@import "https://use.typekit.net/jaf5ftz.css";
:root {
  --bg-color: #f7f2e5;
  --accent: #008666;
  --font-color: #14271a;
}

body {
  background-color: var(--bg-color);
}

h1, h2, h3 {
  font-family: thunderhouse-pro, sans-serif;
}

.logo-mobile {
  margin-top: 1rem;
}

.padded {
  padding-top: 9rem;
}

hr {
  background: 1px var(--font-color) !important;
}

.no-bottom-margin {
  margin-bottom: 0;
}

.hand {
  background: url("lapka.01a565bd.webp") 320px no-repeat;
  height: 150px;
  display: none;
}

.contact {
  background: url("lapka.01a565bd.webp") 411px 0 no-repeat;
  position: relative;
}

.contact .paper-plane {
  max-height: 12rem;
}

.contact .details .row {
  margin-top: .5rem;
}

.contact .details .row .name {
  font-size: 2rem;
}

.contact .phone {
  background: url("phone.a3caeac8.svg") center no-repeat;
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.contact .email {
  background: url("at.28cc1b0a.svg") center no-repeat;
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.contact .heading {
  place-content: left;
  padding-bottom: 50px;
  display: grid;
  position: relative;
  top: 32px;
}

.contact .heading h1 {
  grid-area: 1 / 1;
  margin-left: 30px;
}

.contact .heading .box {
  background: var(--accent);
  z-index: -1;
  grid-area: 1 / 1;
  width: 60px;
  height: 60px;
  position: relative;
}

.contact .heading .arrow {
  background: url("arrow.9c947bfb.svg") center no-repeat;
  grid-area: 1 / 2;
  width: 80px;
  height: 80px;
  display: inline;
  position: relative;
  top: -5px;
  left: -170px;
}

#illustration {
  visibility: hidden;
  position: relative;
  top: 0;
  left: 0;
}

#illustration img {
  position: absolute;
  top: 0;
  left: 0;
}

#illustration img.grow:nth-child(0) {
  opacity: 0;
  transition-property: transform, opacity;
  animation: .7s ease-out .1ms forwards growAnimation;
}

#illustration img.grow:nth-child(n) {
  opacity: 0;
  transition-property: transform, opacity;
  animation: .7s ease-out 20.1ms forwards growAnimation;
}

#illustration img.grow:nth-child(2n) {
  opacity: 0;
  transition-property: transform, opacity;
  animation: .7s ease-out 40.1ms forwards growAnimation;
}

#illustration img.grow:nth-child(3n) {
  opacity: 0;
  transition-property: transform, opacity;
  animation: .7s ease-out 60.1ms forwards growAnimation;
}

#illustration img.grow:nth-child(4n) {
  opacity: 0;
  transition-property: transform, opacity;
  animation: .7s ease-out 80.1ms forwards growAnimation;
}

#illustration img.grow:nth-child(5n) {
  opacity: 0;
  transition-property: transform, opacity;
  animation: .7s ease-out .1001s forwards growAnimation;
}

@keyframes growAnimation {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg-color: black;
    --font-color: #c8c8c8;
  }

  i.email, i.phone {
    filter: invert();
  }
}

/*# sourceMappingURL=index.5e328696.css.map */
