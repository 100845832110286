@import url('https://use.typekit.net/jaf5ftz.css');

:root {
  --bg-color: #F7F2E5;
  --accent: #008666;
  --font-color: #14271A;
}

body {
  background-color: var(--bg-color);
}

h1, h2, h3 {
  font-family: "thunderhouse-pro", sans-serif;
}

.logo-mobile {
  margin-top: 1rem;
}

.padded {
  padding-top: 9rem;
}

hr {
  background: 1px var(--font-color) !important;
}

.no-bottom-margin {
  margin-bottom: 0;
}

.hand {
  display: none;
  height: 150px;
  background: url("lapka.png?as=webp") no-repeat 320px;
}

.contact {
  background: url("lapka.png?as=webp") no-repeat 411px 0;
  position: relative;
  //top: -145px;

  .paper-plane {
    max-height: 12rem;
  }

  .details .row {
    margin-top: .5rem;

    .name {
      font-size: 2rem;
    }
  }

  .phone {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    background: url("phone.svg") no-repeat center;
  }

  .email {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    background: url("at.svg") no-repeat center;
  }

  .heading {
    position: relative;
    top: 32px;
    display: grid;
    place-content: left;
    padding-bottom: 50px;

    h1 {
      grid-area: 1/1;
      margin-left: 30px
    }

    .box {
      grid-area: 1/1;
      width: 60px;
      height: 60px;
      background: var(--accent);
      z-index: -1;
      position: relative;
    }

    .arrow {
      width: 80px;
      height: 80px;
      background: url("arrow.svg") no-repeat center;
      left: -170px;
      top: -5px;
      position: relative;
      display: inline;
      grid-area: 1/2;
    }
  }
}

#illustration {
  position: relative;
  top: 0;
  left: 0;
  visibility: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
  }

  @for $i from 0 through 5 {
    img.grow:nth-child(#{$i}n) {
      transition-property: transform, opacity;
      opacity: 0;
      animation: growAnimation 0.7s ease-out 0s 1 normal forwards;
      animation-delay: #{($i + 0.005) * 0.02}s;
    }
  }


  @keyframes growAnimation {
    0% {
      opacity: 0;
      transform: scale(0);

    }

    100% {
      opacity: 100%;
      transform: scale(100%);

    }
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg-color: black;
    --font-color: #c8c8c8;
  }

  i.email, i.phone {
    filter: invert(100%);
  }
}
